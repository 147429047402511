// src/App.tsx

import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import Courtsidecrypto from "./page/Courtsidecrypto";


const Home = () => {
  React.useEffect(() => {
    window.location.href = `https://tomo.inc`;
  }, []);
  return <div></div>;
};

const defaultRedirect = () => {
  return (
    <div className="tomo-wrapper">
      <img width={181} src="/images/tomo-logo-new.svg" alt="" />
      <div className="tomo-content">
        <div className="tomo-title">New Tomo, New Possibilities</div>
        <span className="tomo-desc">
          Experience a fresh approach to creator exclusivity and content
          monetization on Tomo. Engage with your favorite creators through
          token-gated chat rooms
        </span>
        <div className="download-now">Download now on</div>
        <div
          className="download-btn"
          onClick={() => {
            window.open(
              "https://apps.apple.com/us/app/tomo-inc/id6468010287",
              "_blank"
            );
          }}
        >
          <img width={146} src="/images/tomo-app-store.svg" alt="" />
        </div>
        <div
          className="download-btn"
          onClick={() => {
            window.open(
              "https://play.google.com/store/apps/details?id=tomo.app.unyx",
              "_blank"
            );
          }}
        >
          <img width={150} src="/images/tomo-google-play.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

const StoreRedirect = () => {
  const { username } = useParams<{ username: string }>();
  // const [platform, setPlatform] = React.useState<string | null>(null);

  React.useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    let timerId: NodeJS.Timeout | null = null;
    let timerClose: NodeJS.Timeout | null = null;

    const openAppStore = () => {
      if (timerId) clearTimeout(timerId);
      if (/android/.test(userAgent)) {
        window.location.href = `https://play.google.com/store/apps/details?id=tomo.app.unyx`;
      } else if (/iphone|ipad|ipod/.test(userAgent)) {
        window.location.href = `https://apps.apple.com/us/app/tomo-inc/id6468010287`;
      } else {
        window.location.href = `https://tomo.inc/`;
      }
    };

    const tryOpenApp = () => {
      window.location.href = `tomo://m.tomo.inc/${username}`;
      if (window.history.length > 1) {
        setTimeout(() => window.history.back(), 2000); 
      }

      timerId = setTimeout(openAppStore, 1000);
    };

    const handleBlur = () => {
      if (timerId) clearTimeout(timerId);
      if (timerClose) clearTimeout(timerClose);
    };
    window.addEventListener("blur", handleBlur);

    tryOpenApp();

    return () => {
      window.removeEventListener("blur", handleBlur);
      if (timerId) clearTimeout(timerId);
      if (timerClose) clearTimeout(timerClose);
    };
  }, [username]);

  return defaultRedirect();
};

const StoryRedirect = () => {
  const { storyId } = useParams<{ storyId: string }>();

  React.useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    let timerId: NodeJS.Timeout | null = null;

    const openAppStore = () => {
      if (timerId) clearTimeout(timerId);
      if (/android/.test(userAgent)) {
        window.location.href = `https://play.google.com/store/apps/details?id=tomo.app.unyx`;
      } else if (/iphone|ipad|ipod/.test(userAgent)) {
        window.location.href = `https://apps.apple.com/us/app/tomo-inc/id6468010287`;
      } else {
        window.location.href = `https://tomo.inc/`;
      }
    };

    const tryOpenApp = () => {
      window.location.href = `tomo://m.tomo.inc/story/${storyId}`;

      timerId = setTimeout(openAppStore, 500);
    };

    const handleBlur = () => {
      if (timerId) clearTimeout(timerId);
    };
    window.addEventListener("blur", handleBlur);

    tryOpenApp();

    return () => {
      window.removeEventListener("blur", handleBlur);
      if (timerId) clearTimeout(timerId);
    };
  }, [storyId]);

  return defaultRedirect();
};

const InviteCodeRedirect = () => {
  const { inviteCode } = useParams<{ inviteCode: string }>();

  React.useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    let timerId: NodeJS.Timeout | null = null;

    const openAppStore = () => {
      if (timerId) clearTimeout(timerId);
      if (/android/.test(userAgent)) {
        window.location.href = `https://play.google.com/store/apps/details?id=tomo.app.unyx`;
      } else if (/iphone|ipad|ipod/.test(userAgent)) {
        window.location.href = `https://apps.apple.com/us/app/tomo-inc/id6468010287`;
      } else {
        window.location.href = `https://tomo.inc/`;
      }
    };

    const tryOpenApp = () => {
      window.location.href = `tomo://m.tomo.inc/u/${inviteCode}`;

      timerId = setTimeout(openAppStore, 500);
    };

    const handleBlur = () => {
      if (timerId) clearTimeout(timerId);
    };
    window.addEventListener("blur", handleBlur);

    tryOpenApp();

    return () => {
      window.removeEventListener("blur", handleBlur);
      if (timerId) clearTimeout(timerId);
    };
  }, [inviteCode]);

  return defaultRedirect();
};

const LiveRedirect = () => {
  const { liveId } = useParams<{ liveId: string }>();

  React.useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    let timerId: NodeJS.Timeout | null = null;

    const openAppStore = () => {
      if (timerId) clearTimeout(timerId);
      if (/android/.test(userAgent)) {
        window.location.href = `https://play.google.com/store/apps/details?id=tomo.app.unyx`;
      } else if (/iphone|ipad|ipod/.test(userAgent)) {
        window.location.href = `https://apps.apple.com/us/app/tomo-inc/id6468010287`;
      } else {
        window.location.href = `https://tomo.inc/`;
      }
    };

    const tryOpenApp = () => {
      window.location.href = `tomo://m.tomo.inc/live/${liveId}`;

      timerId = setTimeout(openAppStore, 500);
    };

    const handleBlur = () => {
      if (timerId) clearTimeout(timerId);
    };
    window.addEventListener("blur", handleBlur);

    tryOpenApp();

    return () => {
      window.removeEventListener("blur", handleBlur);
      if (timerId) clearTimeout(timerId);
    };
  }, [liveId]);

  return defaultRedirect();
};
const GroupRedirect = () => {
  const { groupId } = useParams<{ groupId: string }>();

  React.useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    let timerId: NodeJS.Timeout | null = null;

    const openAppStore = () => {
      if (timerId) clearTimeout(timerId);
      if (/android/.test(userAgent)) {
        window.location.href = `https://play.google.com/store/apps/details?id=tomo.app.unyx`;
      } else if (/iphone|ipad|ipod/.test(userAgent)) {
        window.location.href = `https://apps.apple.com/us/app/tomo-inc/id6468010287`;
      } else {
        window.location.href = `https://tomo.inc/`;
      }
    };

    const tryOpenApp = () => {
      window.location.href = `tomo://m.tomo.inc/group/${groupId}`;

      timerId = setTimeout(openAppStore, 500);
    };

    const handleBlur = () => {
      if (timerId) clearTimeout(timerId);
    };
    window.addEventListener("blur", handleBlur);

    tryOpenApp();

    return () => {
      window.removeEventListener("blur", handleBlur);
      if (timerId) clearTimeout(timerId);
    };
  }, [groupId]);

  return defaultRedirect();
};
const App = () => (
  <Router>
    <Routes>
      <Route index element={<Home />} />
      <Route path=":username" element={<StoreRedirect />} />
      <Route path="story/:storyId" element={<StoryRedirect />} />
      <Route path="u/:inviteCode" element={<InviteCodeRedirect />} />
      <Route path="live/:liveId" element={<LiveRedirect />} />
      <Route path="group/:groupId" element={<GroupRedirect />} />
      <Route path="/courtsidecrypto" element={<Courtsidecrypto />} />
    </Routes>
  </Router>
);

export default App;
